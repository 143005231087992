//#region Query

export class UserUpdatePaxBody
{
    address: string = "";
    allergies: string = "";
    birthDate: string = "";
    city: string = "";
    firstName: string = "";
    gender: string = "";
    lastName: string = "";
    medicalCondition: string = "";
    password: string = "";
    postalCode: string = "";
    province: string = "";
    telephone: string = "";
    telephoneExt: string = "";
    telephoneWork: string = "";

    constructor(p_Model: UserPaxModel, p_Password: string)
    {
        this.address = p_Model.address;
        this.allergies = p_Model.allergies;
        this.birthDate = p_Model.birthDate;
        this.city = p_Model.city;
        this.firstName = p_Model.firstName;
        this.gender = p_Model.gender;
        this.lastName = p_Model.lastName;
        this.medicalCondition = p_Model.medicalCondition;
        this.postalCode = p_Model.postalCode;
        this.province = p_Model.province;
        this.telephone = p_Model.telephone;
        this.telephoneExt = p_Model.telephoneExt;
        this.telephoneWork = p_Model.telephoneWork;

        this.password = p_Password;
    }
}

export class UserUpdateCustomerBody
{
    contactFirstName: string = "";
    contactLastName: string = "";
    contactTitle: string = "";
    password: string = "";

    constructor(p_Model: UserCustomerModel, p_Password: string)
    {
        this.contactFirstName = p_Model.contactFirstName;
        this.contactLastName = p_Model.contactLastName;
        this.contactTitle = p_Model.contactTitle;

        this.password = p_Password;
    }
}

export class UserUpdateEmailBody
{
    newEmail: string = "";
    password: string = "";

    constructor(p_NewEmail: string, p_Password: string)
    {
        this.newEmail = p_NewEmail;
        this.password = p_Password;
    }
}

export class UserUpdatePasswordBody
{
    currentPassword: string = "";
    newPassword: string = "";

    constructor(p_CurrentPassword: string, p_NewPassword: string)
    {
        this.currentPassword = p_CurrentPassword;
        this.newPassword = p_NewPassword;
    }
}

//#endregion

//#region Model

export class UserCustomerModel
{
    code: string = "";

    contactFirstName: string = "";
    contactLastName: string = "";
    contactTitle: string = "";
    email: string = "";
    name: string = "";
    tel: string = "";
    telExt: string = "";
}

export class UserPaxModel
{
    address: string = "";
    allergies: string = "";
    birthDate: string = "";
    city: string = "";
    email: string = "";
    gender: string = "";
    firstName: string = "";
    lastName: string = "";
    medicalCondition: string = "";
    postalCode: string = "";
    province: string = "";
    telephone: string = "";
    telephoneExt: string = "";
    telephoneWork: string = "";
}

export class UserCustomerProjectModel
{
    code: string = "";
}

//#endregion