
    import { Component, Model, Prop, Vue } from "vue-property-decorator";
    import moment, { Moment } from "moment";

    @Component
    export default class DatePicker extends Vue
    {

        @Model("change", { type: String }) readonly model!: string
        @Prop({ type: String, default: "" }) readonly id!: string;
        @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

        isOpen: boolean = false;
        inputValue: string = "";
        tableDisplayed: DatePickerTableEnum = DatePickerTableEnum.Calendar;
        momentContext: Moment = this.momentDateSelected
        yearIndex: number = 0;

        get getDateFormat(): string
        {
            return this.$tStore?.state?.configState?.setting?.license?.dateFormat ?? "YYYY-MM-DD";
        }

        get momentDateSelected(): Moment
        {
            let tmpMoment: Moment = moment(this.model);
            if (tmpMoment.isValid())
            {
                this.inputValue = tmpMoment.format(this.getDateFormat);
                this.momentContext = tmpMoment;
                return moment(this.model);
            }
            else
            {
                return moment();
            }
        }
        set momentDateSelected(p_Value: Moment)
        {
            this.$emit("change", p_Value.format("YYYY-MM-DD"));
        }

        get getMonthSelected(): string
        {
            return this.getMonthsNames[this.momentContext.month()];
        }

        get getYearSelected(): number
        {
            return this.momentContext.year();
        }

        get getDatePickerTableEnum(): typeof DatePickerTableEnum
        {
            return DatePickerTableEnum;
        }

        get getCalendar()
        {
            const startWeek = this.momentContext.startOf("month").week();
            const endWeek = startWeek + 5;

            let calendar = [];

            for (var week = startWeek; week <= endWeek; week++)
            {
                calendar.push(
                    Array(7).fill(0).map((n, i) =>
                    {
                        return new DatePickerDayInformation(moment(this.momentContext).week(week).startOf("week").add(n + i, "day"), this.momentContext.format("MM"));
                    })
                );
            }

            return calendar;

        }

        get inputProxy(): string
        {
            return this.inputValue;
        }

        set inputProxy(p_Value: string)
        {
            this.inputValue = p_Value;
            let tmpMoment = moment(p_Value, this.getDateFormat);
            if (tmpMoment.isValid())
            {
                this.momentDateSelected = moment(p_Value, this.getDateFormat);
                this.momentContext = tmpMoment;
            }
            else
            {
                this.$emit("change", "");
            }
        }

        mounted()
        {
            window.addEventListener("click", this.onClickExternDatepicker, true);
        }

        beforeDestroy()
        {
            window.removeEventListener("click", this.onClickExternDatepicker, true);
        }

        onClickExternDatepicker(e: Event)
        {
            if (e.target)
            {
                if (!this.$el.contains((e.target as Node)))
                {
                    this.isOpen = false;
                }
            }
        }

        onTableSelectionClick()
        {
            if (this.tableDisplayed === DatePickerTableEnum.Calendar)
            {
                this.tableDisplayed = DatePickerTableEnum.Month;
            }
            else if (this.tableDisplayed === DatePickerTableEnum.Month)
            {
                this.tableDisplayed = DatePickerTableEnum.Year;
            }
        }

        onDayClick(p_MomentDay: Moment)
        {
            this.momentDateSelected = p_MomentDay;
            this.momentContext = p_MomentDay;
            this.isOpen = false;
        }

        onMonthClick(p_MonthIndex: number)
        {
            this.momentContext = moment(this.momentContext).month(p_MonthIndex);
            this.tableDisplayed = DatePickerTableEnum.Calendar;
        }

        onYearClick(p_Year: number)
        {
            this.momentContext = moment(this.momentContext).year(p_Year);
            this.tableDisplayed = DatePickerTableEnum.Month;
            this.yearIndex = 0;
        }

        classCssSelectedDate(p_momentDay: Moment): string
        {
            if (this.momentDateSelected.isSame(p_momentDay) && this.model.length > 0)
            {
                return "date-picker-day-selected";
            }
            else
            {
                return "";
            }
        }

        classCssSelectedMonth(p_MonthIndex: number): string
        {
            if (this.momentDateSelected.month() === p_MonthIndex && this.momentDateSelected.year() === this.getYearSelected && this.model.length > 0)
            {
                return "date-picker-month-selected";
            }
            else
            {
                return "";
            }
        }

        classCssSelectedYear(p_Year: number)
        {
            if (this.momentDateSelected.year() === p_Year && this.model.length > 0)
            {
                return "date-picker-year-selected";
            }
            else
            {
                return "";
            }
        }

        onArrowLeftClick()
        {
            if (this.tableDisplayed === DatePickerTableEnum.Calendar)
            {
                this.momentContext = moment(this.momentContext).subtract(1, "M");
            }
            else if (this.tableDisplayed === DatePickerTableEnum.Month)
            {
                this.momentContext = moment(this.momentContext).subtract(1, "y");
            }
            else
            {
                this.yearIndex--;
            }
        }

        onArrowRightClick()
        {
            if (this.tableDisplayed === DatePickerTableEnum.Calendar)
            {
                this.momentContext = moment(this.momentContext).add(1, "M");
            }
            else if (this.tableDisplayed === DatePickerTableEnum.Month)
            {
                this.momentContext = moment(this.momentContext).add(1, "y");
            }
            else
            {
                this.yearIndex++;
            }
        }

    }

    enum DatePickerTableEnum
    {
        Calendar = 0,
        Month = 1,
        Year = 2
    }

    class DatePickerDayInformation
    {
        momentDay: Moment;
        classCss: string;

        constructor(p_MomentDay: Moment, p_MonthSelected: string)
        {
            this.momentDay = p_MomentDay;
            if (p_MomentDay.format("MM") === p_MonthSelected)
            {
                this.classCss = "date-picker-day-in-month";
            }
            else
            {
                this.classCss = "date-picker-day-not-in-month";
            }

        }
    }
