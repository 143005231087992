
    import { Component, PropSync, Vue, Prop, Watch } from "vue-property-decorator";

    @Component
    export default class PasswordConfirmationModal extends Vue
    {

        @PropSync("isDisplayed", { type: Boolean }) private syncedIsDisplayed!: boolean;
        @Prop({ type: String }) private callback!: string;

        private confirmPassword: string = "";

        setFocus()
        {
            //@ts-ignore
            this.$refs.focusThis.focus();
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        onClickSubmit()
        {
            this.$emit(this.callback, this.confirmPassword);
            this.syncedIsDisplayed = false;
            this.confirmPassword = "";
        }
    }
